<template>
  <b-table-simple
    caption-top
    class="text-center table-hour"
    responsive
  >
    <colgroup><col><col><col></colgroup>
    <colgroup><col><col><col></colgroup>
    <colgroup><col><col><col></colgroup>
    <b-thead>

      <b-tr>
        <b-th>AÇÕES </b-th>
        <b-th>PROCEDIMENTOS</b-th>
        <b-th>HORÁRIOS</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr>
        <b-th
          class="head"
          rowspan="3"
        >
          Registro e Consulta de Ativos Financeiros
        </b-th>

        <b-th>
          Registro e atualização de Ativos Financeiros por Credenciadoras e Subcredenciadoras
        </b-th>
        <b-td>De seg. a sex., 08h00 às 19h00 </b-td>
      </b-tr>
      <b-tr>
        <b-th>
          Disponibilização de Ativos Financeiros atualizados para Participantes Favorecidos
        </b-th>
        <b-td>De seg. a sex., 08h00 às 19h00 </b-td>
      </b-tr>

      <b-tr>
        <b-th>
          Consulta de Ativos Financeiros por Participantes Favorecidos
        </b-th>
        <b-td>De seg. a sex., 08h00 às 19h00  </b-td>
      </b-tr>

      <b-tr>
        <b-th
          class="head"
          rowspan="3"
        >
          Averbação e Consulta de Situação de Contratos
        </b-th>

        <b-th>
          Averbação de Antecipação Pós-Contratada por Credenciadoras e Subcredenciadoras
        </b-th>
        <b-td>De seg. a sex., 08h00 às 19h00  </b-td>
      </b-tr>
      <b-tr>
        <b-th>
          Envio de efeitos de Averbações para Credenciadoras e Subcredenciadoras
        </b-th>
        <b-td>De seg. a sex., 08h00 às 19h00  </b-td>
      </b-tr>

      <b-tr>
        <b-th>
          Consulta de Averbações
        </b-th>
        <b-td>De seg. a sex., 08h00 às 19h00  </b-td>
      </b-tr>

      <b-tr>
        <b-th
          class="head"
          rowspan="3"
        >
          Opt-ins e Opt-outs
        </b-th>

        <b-th>
          Envio de Opt-ins
        </b-th>
        <b-td>De seg. a sex., 08h00 às 19h00  </b-td>
      </b-tr>
      <b-tr>
        <b-th>
          Envio de Opt-outs
        </b-th>
        <b-td>De seg. a sex., 08h00 às 19h00  </b-td>
      </b-tr>

    </b-tbody>
  </b-table-simple>

</template>

<script>
import {
    BTableSimple, BTbody, BTr, BTd, BTh, BThead,
} from 'bootstrap-vue';

export default {
    components: {
        BTableSimple, BTbody, BTr, BTd, BTh, BThead,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss">
.dark-layout {
    .table-hour {
        th.head {
            background-color: #343d55;
        }
    }
}

.table-hour {
    th.head {
        background-color: #f3f2f7;
    }
}
</style>
