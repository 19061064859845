<template>
  <div>
    <b-card>
      <hour-table v-if="register === 'TAG'" />
      <hour-table-cerc v-if="register=== 'CERC'" />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue';

import HourTable from '../components/HourTable.vue';
import HourTableCerc from '../components/HourTableCerc.vue';

export default {
    components: {
        BCard,
        HourTable,
        HourTableCerc,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },

        register: {
            type: String,
            default: () => 'TAG',
        },
    },
};
</script>
