<template>
  <b-container>
    <b-tabs>
      <b-tab title="TAG">
        <Tag />
      </b-tab>

      <b-tab title="CERC">
        <Cerc />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import { BContainer, BTab, BTabs } from 'bootstrap-vue';
import Tag from './pages/Tag.vue';
import Cerc from './pages/Cerc.vue';

export default {
    components: {
        Tag,
        BContainer,
        BTab,
        BTabs,
        Cerc,
    },
};
</script>
