<template>
  <RegisterTemplate
    register="CERC"
    :items="[
      { name: 'Opt-Ins', hour: 'De seg. a sex., 08h00 às 19h00' },
      { name: 'Averbação de Bloqueio judicial, Averbação de Alteração de Titularidade e Atualizações de Averbação', hour: 'De seg. a sex., 08h00 às 19h00' },
      { name: 'Consulta de Ativos Financeiros por Participantes Favorecidos', hour: 'De seg. a sex., 08h00 às 19h00' }
    ]"
  />
</template>

<script>
import RegisterTemplate from '../templates/template.vue';

export default {
    components: {
        RegisterTemplate,
    },
};
</script>
