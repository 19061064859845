<template>
  <RegisterTemplate
    :items="[
      { type: 'Teste', name: 'Opt-Ins', hour: 'De seg. a sex., 09h00 às 20h59' },
      { name: 'Averbação de Ônus e Gravames, Averbação de Alteração de Titularidade e Atualizações de Averbação', hour:'De seg. a sex., 09h00 às 20h' },
      { name: 'Consulta de Ativos Financeiros por Participantes Favorecidos', hour:'De seg. a sex., 00h00 às 23h59' }
    ]"
  />
</template>

<script>
import RegisterTemplate from '../templates/template.vue';

export default {
    components: {
        RegisterTemplate,
    },
};
</script>
